import * as React from "react"
import {
  Box,
  Button,
  Center,
  chakra,
  Flex,
  Heading,
  Image,
  Link,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import { ArrowBackIcon } from "@chakra-ui/icons"
import { graphql, Link as GatsbyLink, PageProps } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { gsap } from "gsap"
import "react-image-gallery/styles/css/image-gallery.css"

import {
  IHomePlan,
  ILevelCaptions,
  ILevelMedia,
  TPlanLevel,
} from "../components/plans"
import { GatsbyImageGallery } from "../components/imageGalleries"
import Layout from "../components/layout"
import ContactForm from "../components/contactForm"
import { MobileShareMenu } from "../components/socialSharing"
import SEO from "../components/CoreUI/SEO"
import { MediaContext } from "../components/CoreUI/MediaContext"
import AnchorLinks from "../components/anchorLinks"

interface TemplateProps extends PageProps {
  data: {
    plan: IHomePlan
  }
}

function HomePlanTemplate({ data, location }: TemplateProps): JSX.Element {
  const { width } = React.useContext(MediaContext)
  const [floorPlanType, setFloorPlanType] = React.useState<TPlanLevel>(`Main`)
  const floorPlanCaptionRef = React.useRef()
  const anchorWrapperRef = React.useRef<HTMLDivElement>()
  const introRef = React.useRef<HTMLDivElement>()
  const specsRef = React.useRef<HTMLDivElement>()
  const planRef = React.useRef<HTMLDivElement>()

  const { plan } = data

  let captions: ILevelCaptions = {}
  let floorPlans: ILevelMedia[] = []

  if (plan.mainLevelDescription) {
    captions[`Main`] = {
      description: plan.mainLevelDescription,
      heading: plan.mainLevelHeading,
      level: plan.levels === 3 || plan.lowerLevelPlan ? 2 : 1,
      type: `Main`,
    }
  }

  if (plan.mainLevelPlan) {
    floorPlans.push({
      plan: plan.mainLevelPlan,
      level: plan.levels === 3 || plan.lowerLevelPlan ? 2 : 1,
      type: `Main`,
    })
  }

  if (plan.upperLevelDescription) {
    captions[`Upper`] = {
      description: plan.upperLevelDescription,
      heading: plan.upperLevelHeading,
      level: plan.levels === 3 || plan.lowerLevelPlan ? 3 : 2,
      type: `Upper`,
    }
  }

  if (plan.upperLevelPlan) {
    floorPlans.push({
      plan: plan.upperLevelPlan,
      level: plan.levels === 3 || plan.lowerLevelPlan ? 3 : 2,
      type: `Upper`,
    })
  }

  if (plan.lowerLevelDescription) {
    captions[`Lower`] = {
      description: plan.lowerLevelDescription,
      heading: plan.lowerLevelHeading,
      level: 1,
      type: `Lower`,
    }
  }

  if (plan.lowerLevelPlan) {
    floorPlans.push({
      plan: plan.lowerLevelPlan,
      level: 1,
      type: `Lower`,
    })
  }

  React.useEffect(() => {
    gsap.to(floorPlanCaptionRef?.current, {
      duration: 0.225,
      opacity: 1,
    })
  }, [floorPlanType])

  const onPlanChange = (nextIndex: number): void => {
    if (Object.keys(captions).length > 1) {
      gsap.to(floorPlanCaptionRef?.current, {
        duration: 0.225,
        opacity: 0,
        onComplete: () => {
          setFloorPlanType(floorPlans[nextIndex].type)
        },
      })
    } else {
      setFloorPlanType(floorPlans[nextIndex].type)
    }
  }

  return (
    <Layout>
      <SEO
        meta={{
          description: plan.description.description.slice(0, 146) + `...`,
          image: plan.elevations[0].gatsbyImageData.images.fallback.src,
          imageAlt: plan.elevations[0].description,
        }}
        title={`${plan.name} | Sundog Homes`}
        url={location.href}
      />
      <Box id="anchor-container" ref={anchorWrapperRef}>
        <AnchorLinks
          activeLinkColor="red.500"
          container={anchorWrapperRef}
          d={{ base: `none`, lg: `block` }}
          links={[
            { container: introRef, title: `Intro` },
            { container: specsRef, title: `Overview` },
            { container: planRef, title: `Plan` },
          ]}
          title="Contents:"
        />
        <Flex
          align="center"
          direction="column"
          justify="center"
          mt={4}
          w="100vw"
        >
          {location.state && location.state.hasOwnProperty(`isLocalTraffic`) ? (
            <Button
              leftIcon={<ArrowBackIcon />}
              onClick={() => window.history.back()}
              size="sm"
              variant="link"
            >
              Go Back to All Plans
            </Button>
          ) : (
            <GatsbyLink to="/home-plans/">
              <Button leftIcon={<ArrowBackIcon />} size="sm" variant="link">
                View All Plans
              </Button>
            </GatsbyLink>
          )}
          <Flex
            align="center"
            justify="center"
            maxW="800px"
            mx="auto"
            pos="relative"
            w="100%"
          >
            <Heading
              id="intro"
              fontWeight="black"
              mt={6}
              py={2}
              ref={introRef}
              size="3xl"
              textAlign="center"
              textShadow="lg"
            >
              The {plan.name}
            </Heading>
            <MobileShareMenu
              mediaUrl={plan.elevations[0].gatsbyImageData.images.fallback.src}
              title={plan.name}
              url={location.href}
              buttonProps={{
                "aria-label": `Share the ${plan.name} on social media`,
                bottom: `50px`,
                d: `block`,
                right: `10px`,
              }}
            />
          </Flex>
          {plan.elevations?.length && (
            <Box maxW="95vw" my={6} overflow="hidden" rounded="lg" w="800px">
              <GatsbyImageGallery
                fullscreenProps={{
                  maxW: `750px`,
                  w: `95vw`,
                }}
                galleryProps={{ showThumbnails: plan.elevations.length > 1 }}
                images={plan.elevations}
              />
            </Box>
          )}
          <Box
            border="1px"
            borderColor="gray.100"
            id="specs"
            maxW="95vw"
            mb={6}
            py={2}
            ref={specsRef}
            rounded="lg"
            shadow="sm"
            w="800px"
          >
            <Table
              className="plan-features"
              size={width >= 740 ? `lg` : width >= 500 ? `md` : `sm`}
            >
              <TableCaption>Plan Specs</TableCaption>
              <Thead>
                <Tr>
                  <Th>Beds</Th>
                  <Th>Baths</Th>
                  <Th>SqFt</Th>
                  {width >= 740 && (
                    <>
                      <Th>Levels</Th>
                      <Th>Width (ft)</Th>
                      <Th>Depth (ft)</Th>
                    </>
                  )}
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{plan.beds}</Td>
                  <Td>{plan.baths}</Td>
                  <Td>{plan.sqft.toLocaleString(`en-US`)}</Td>
                  {width >= 740 && (
                    <>
                      <Td>{plan.levels}</Td>
                      <Td>{plan.width || `——`}</Td>
                      <Td>{plan.depth || `__`}</Td>
                    </>
                  )}
                </Tr>
              </Tbody>
              {width < 740 && (
                <>
                  <Thead>
                    <Tr>
                      <Th>Levels</Th>
                      <Th>Width (ft)</Th>
                      <Th>Depth (ft)</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>{plan.levels}</Td>
                      <Td>{plan.width || `——`}</Td>
                      <Td>{plan.depth || `__`}</Td>
                    </Tr>
                  </Tbody>
                </>
              )}
            </Table>
          </Box>
        </Flex>
        <Flex align="center" direction="column" pb={12} w="100vw">
          <Box
            borderBottom="2px"
            borderColor="gray.100"
            id="description"
            maxW="95vw"
            mt={6}
            mb={10}
            w="800px"
          >
            <Heading
              as="h3"
              color="red.500"
              id="overview"
              mt={4}
            >
              Overview
            </Heading>
          </Box>
          <Box fontSize="lg" maxW="95vw" w="800px">
            <div
              dangerouslySetInnerHTML={{
                __html: plan.description.childMarkdownRemark.html,
              }}
            />
          </Box>
        </Flex>
        {floorPlans?.length && (
          <Flex
            alignItems="center"
            bg="gray.100"
            direction="column"
            mt={4}
            py={12}
            w="100vw"
          >
            <Box
              borderBottom="2px"
              borderColor="gray.200"
              fontSize="lg"
              id="floor-plan"
              maxW="95vw"
              mb={10}
              mt={6}
              ref={planRef}
              w="800px"
            >
              <Heading color="red.500" id="plan" mt={4}>
                Plan
              </Heading>
            </Box>
            <Flex
              alignItems="start"
              flexDir={{ base: `column-reverse`, md: `row` }}
              justifyContent="space-between"
              maxW="95vw"
              rounded="lg"
              w="800px"
            >
              <Box px={[2, , 0]} py={6} w={{ base: `95vw`, md: `40%` }}>
                <Text
                  color="red.500"
                  fontSize="sm"
                  fontWeight="semibold"
                  letterSpacing="wide"
                  mb={{ base: 2, md: 6 }}
                  textTransform="uppercase"
                >
                  LEVEL{" "}
                  {floorPlans.length > plan.levels
                    ? 1
                    : floorPlans.find(
                        (floorPlan: ILevelMedia) =>
                          floorPlan.type === floorPlanType
                      ).level}
                  {floorPlanType !== `Main` && plan.levels === 1 ? `+` : ``}/
                  {plan.levels}
                  {floorPlans.length > plan.levels ? `+` : ``}
                </Text>
                <Box ref={floorPlanCaptionRef}>
                  <Text
                    fontSize="2xl"
                    fontWeight="extrabold"
                    lineHeight="shorter"
                    pb={4}
                  >
                    {captions[
                      floorPlanType in captions ? floorPlanType : `Main`
                    ]?.heading || null}
                  </Text>
                  <Box
                    fontSize="lg"
                    dangerouslySetInnerHTML={{
                      __html:
                        captions[
                          floorPlanType in captions ? floorPlanType : `Main`
                        ]?.description.childMarkdownRemark.html || null,
                    }}
                  ></Box>
                </Box>
              </Box>
              <Box
                h="100%"
                px={[2, , 0]}
                py={4}
                w={{ base: `95vw`, md: `50%` }}
              >
                <GatsbyImageGallery
                  buttonProps={{
                    bg: `blackAlpha.400`,
                    rounded: `full`,
                    size: `md`,
                    _hover: { bg: `blackAlpha.700` },
                  }}
                  fullscreenHeight="100%"
                  fullscreenProps={{
                    h: `1000px`,
                    maxH: `100vh`,
                    maxW: `1000px`,
                    w: `100vw`,
                  }}
                  galleryProps={{ showThumbnails: false }}
                  images={floorPlans.map(
                    (floorPlan: ILevelMedia) => floorPlan.plan
                  )}
                  itemWrapperProps={{
                    bg: `gray.100`,
                    d: `flex`,
                    h: [`350px`, `500px`, `350px`],
                    justifyContent: `center`,
                    mx: `auto`,
                  }}
                  objectFit="contain"
                  onBeforeSlide={onPlanChange}
                  showNavShadow={false}
                />
              </Box>
            </Flex>
          </Flex>
        )}
      </Box>
      <Center pos="relative" py={24} w="100vw">
        <Box h="100%" left="0px" pos="absolute" top="0px" w="100%">
          <Image
            alt={plan.elevations[0].description}
            as={GatsbyImage}
            className="squared"
            filter="blur(3px) opacity(0.5) saturate(0.5)"
            h="100%"
            image={plan.elevations[0].gatsbyImageData}
            w="100%"
          />
        </Box>
        <Box
          bg="gray.200"
          h="100%"
          left="0px"
          pos="absolute"
          style={{ mixBlendMode: "multiply" }}
          top="0px"
          w="100%"
        />
        <Box
          bg="white"
          maxW={{ base: `95vw`, sm: `500px`, md: `700px` }}
          my={[6, 4, 0]}
          p={8}
          rounded="xl"
          shadow="lg"
          zIndex="1"
        >
          <Heading as="h4" fontWeight="extrabold">
            Questions about {plan.name}?
          </Heading>
          <Text fontSize="lg" my={4}>
            We're here to help — just let us know what you need.
          </Text>
          <ContactForm
            buttonColor="red.500"
            buttonId="plan-contact"
            eventLabel={plan.name}
            homePlan={plan.name}
            includeTextArea
          />
        </Box>
      </Center>
    </Layout>
  )
}

export default HomePlanTemplate

export const query = graphql`
  query($name: String!) {
    plan: contentfulHomePlan(name: { eq: $name }) {
      baths
      beds
      depth
      description {
        childMarkdownRemark {
          html
        }
        description
      }
      ebook {
        file {
          url
          details {
            size
          }
          fileName
        }
      }
      elevations {
        description
        gatsbyImageData(width: 800)
      }
      garage
      levels
      lowerLevelDescription {
        childMarkdownRemark {
          html
        }
      }
      lowerLevelHeading
      lowerLevelPlan {
        description
        gatsbyImageData(width: 800)
      }
      mainLevelDescription {
        childMarkdownRemark {
          html
        }
      }
      mainLevelHeading
      mainLevelPlan {
        description
        gatsbyImageData(width: 800)
      }
      name
      series {
        name
        primaryColor
        secondaryColor
        style
        slogan
      }
      slug
      sqft
      upperLevelDescription {
        childMarkdownRemark {
          html
        }
      }
      upperLevelHeading
      upperLevelPlan {
        description
        gatsbyImageData(width: 800)
      }
      width
    }
  }
`
