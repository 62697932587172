import * as React from "react"
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react"

type ResultTypes = `info` | `warning` | `success` | `error`

type FormResultProps = {
  body?: string
  flexDirection?: `column` | `row` | (`column` | `row`)[]
  h?: string | number
  header?: string
  onClose?: () => void
  type: ResultTypes
}

function FormResult({
  body,
  flexDirection = `column`,
  h,
  header,
  onClose,
  type,
}: FormResultProps): JSX.Element {
  const isColumn = flexDirection === `column`
  return (
    <Alert
      alignItems={isColumn ? `center` : `start`}
      flexDirection={flexDirection}
      h={h}
      justifyContent="center"
      status={type}
      variant="subtle"
    >
      <AlertIcon
        boxSize={isColumn ? `40px` : `24px`}
        mb={isColumn ? 4 : `auto`}
      />
      {header && <AlertTitle fontSize="lg">{header}</AlertTitle>}
      {body && (
        <AlertDescription
          maxWidth="sm"
          mt={isColumn ? 2 : `auto`}
          textAlign="center"
        >
          {body}
        </AlertDescription>
      )}
      <CloseButton onClick={onClose} pos="absolute" right="8px" top="8px" />
    </Alert>
  )
}

export default FormResult
