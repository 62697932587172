import * as React from "react"
import { Field, Form, Formik, FormikHelpers } from "formik"
import {
  Box,
  Button,
  // Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"

import { MarketingContext } from "../components/CoreUI/MarketingContext"
import { ContactFormProps } from "./forms"
import FormResult from "./formResult"
import { fireGtmEvent } from "../utils/gtm"
import type CoreUI from "../components/CoreUI/types"
import { formikHelpers } from "@avldev/gatsby-active-campaign-forms"

function validateName(name?: string): string {
  if (!name) {
    return `We need to know what to call you!`
  }
  return ``
}

function validateEmail(email?: string): string {
  if (!email) {
    return `Oops — an email address is required.`
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    return `Hmm... This doesn't look like a valid email.`
  }
  return ``
}

function validatePhone(phone?: string): string {
  if (phone) {
    const strippedNumber = phone.replace(/[^0-9]/g, ``)
    if (strippedNumber.length < 10) {
      return `Hmm... This appears to be missing some digits`
    } else if (strippedNumber.length > 10) {
      return `Hmm... This appears to have too many digits.`
    } else {
      if (/^(\d)\1+$/g.test(strippedNumber)) {
        return `Nice try ;)`
      }
    }
  }
  return ``
}

const getPlanName = (): string => {
  try {
    const planName = window.sessionStorage.getItem(`planName`) ?? ``
    // window.sessionStorage.removeItem(`planName`)
    return planName
  } catch {
    return ``
  }
}

function ContactForm({
  buttonColor = `red.500`,
  buttonId = ``,
  buttonText = `Submit`,
  context = null,
  errorHeader = `Oops!`,
  errorMessage = `Something went wrong. Please try again.`,
  eventLabel = `Lead`,
  includeTextArea = false,
  homePlan = ``,
  successHeader = `Thanks!`,
  successMessage = `We'll get back to you shortly.`,
}: ContactFormProps): JSX.Element {
  const mkt = React.useContext<CoreUI.TMarketingContext>(MarketingContext)
  const formRef = React.useRef<HTMLFormElement>(null)
  const normalizedContext = Array.isArray(context) ? context : [context]

  const utmParams = mkt.getUtmParamsObject()

  const onSubmit = (values: {}, actions: FormikHelpers<{}>) => {
    const { current: form } = formRef

    const onSubmitEnd = (status) => {
      actions.setSubmitting(false)
      actions.setStatus(status)

      try {
        window.sessionStorage.removeItem(`planName`)
      } catch {}
    }

    const onSubmitSuccess = () => {
      fireGtmEvent({
        eventName: `form_submission`,
        eventAction: `Form Submission`,
        eventCategory: form.getAttribute(`id`),
        eventLabel,
      })
    }

    formikHelpers.onSubmit(
      form,
      { onSubmitEnd, onSubmitSuccess },
      process.env.NODE_ENV
    )
  }

  const planName = homePlan ?? getPlanName()

  return (
    <Formik
      initialValues={{}}
      onSubmit={onSubmit}
    >
      {props => {
        return (
          <Form action="https://sundoghomes.activehosted.com/proc.php" id="contact-form" ref={formRef}>
            {props.status ? (
              <FormResult
                body={props.status === 200 ? successMessage : errorMessage}
                h="465px"
                header={props.status === 200 ? successHeader : errorHeader}
                onClose={() => {
                  props.setStatus(null)
                  props.resetForm()
                }}
                type={props.status === 200 ? `success` : `error`}
              />
            ) : (
              <Flex align="flex-start" flexDir="row" flexWrap="wrap" justify="space-between">
                <input type="hidden" name="u" value="17" />
                <input type="hidden" name="f" value="17" />
                <input type="hidden" name="s" />
                <input type="hidden" name="c" value="0" />
                <input type="hidden" name="m" value="0" />
                <input type="hidden" name="act" value="sub" />
                <input type="hidden" name="v" value="2" />
                <input type="hidden" name="or" value="38956eaf0be68fdd60a57f684ef8eb01" />
                <input type="hidden" name="field[30]" value={planName} />
                <Box w={{ base: `100%`, md: includeTextArea ? `48%` : `100%` }}>
                  <Field validate={validateName}>
                    {({ form }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                        isRequired
                      >
                        <FormLabel htmlFor="fullname">Full Name</FormLabel>
                        <Input
                          name="fullname"
                          id="name"
                          placeholder="Full Name"
                          size="lg"
                        />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field validate={validateEmail}>
                    {({ form }) => (
                      <FormControl
                        isInvalid={form.errors.email && form.touched.email}
                        isRequired
                      >
                        <FormLabel htmlFor="email" mt={4}>
                          Email Address
                        </FormLabel>
                        <Input
                          name="email"
                          id="email"
                          placeholder="Email"
                          size="lg"
                        />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field validate={validatePhone}>
                    {({ form }) => (
                      <FormControl
                        isInvalid={form.errors.phone && form.touched.phone}
                        isRequired={true}
                      >
                        <FormLabel htmlFor="phone" mt={4}>
                          Phone Number
                        </FormLabel>
                        <Input
                          name="phone"
                          id="phone"
                          placeholder="Phone Number"
                          size="lg"
                        />
                        <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field>
                    {() => (
                      <FormControl>
                        <FormLabel htmlFor="field[34]" mt={4}>
                          What kind of project is this?
                        </FormLabel>
                        <Select name="field[34]" id="project-type" size="lg">
                          <option defaultValue="">Select...</option>
                          <option value="Custom Home">Custom Home</option>
                          <option value="Semi-Custom Home">Semi-Custom Home</option>
                          <option value="Residential Renovation/Addition">Residential Renovation/Addition</option>
                          <option value="Commercial New Construction">Commercial New Construction</option>
                          <option value="Commercial Renovation">Commercial Renovation</option>
                          <option value="Planned Development">Planned Development</option>
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box w={{ base: `100%`, md: includeTextArea ? `48%` : `100%` }}>
                  <Field>
                    {() => (
                      <FormControl>
                        <FormLabel htmlFor="field[38]" mt={{ base: 4, lg: 0 }}>
                          When do you want to start your project?
                        </FormLabel>
                        <Select name="field[38]" id="desired-budget" placeholder="" size="lg">
                          <option defaultValue="">Select...</option>
                          <option value="As soon as possible">As soon as possible</option>
                          <option value="Within 6 months">Within 6 months</option>
                          <option value="Within 1 year">Within a year</option>
                          <option value="Within 2 years">Within 2 years</option>
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                  <Field>
                    {() => (
                      <FormControl>
                        <FormLabel htmlFor="field[26]" mt={4}>
                          What's your desired budget?
                        </FormLabel>
                        <Select name="field[26]" id="desired-budget" placeholder="" size="lg">
                          <option defaultValue="">Select...</option>
                          <option value="$50,000  to $200,000">$50,000  to $200,000</option>
                          <option value="$200,000 to $400,000">$200,000 to $400,000</option>
                          <option value="$400,000 to $600,000">$400,000 to $600,000</option>
                          <option value="$600,000 to $800,000">$600,000 to $800,000</option>
                          <option value="$800,000 to $1 million">$800,000 to $1 million</option>
                          <option value="$1 million to $2 million">$1 million to $2 million</option>
                          <option value="$2 million to $4 million">$2 million to $4 million</option>
                          <option value="$4 million +">$4 million +</option>
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                  <Field>
                    {() => (
                      <FormControl>
                        <FormLabel htmlFor="field[37]" mt={4}>
                          Where is your project located?
                        </FormLabel>
                        <Input name="field[37]" id="project-location" placeholder="City, zip, county" size="lg" />
                      </FormControl>
                    )}
                  </Field>
                  {includeTextArea && (
                    <Field name="field[2]">
                      {({ field, form }) => (
                        <FormControl isInvalid={false}>
                          <FormLabel htmlFor="message" mt={4}>
                            Message
                          </FormLabel>
                          <Textarea name="field[2]" h="144px" placeholder="What's on your mind?" size="lg" />
                        </FormControl>
                      )}
                    </Field>
                  )}
                  {/* <Field name="newsletter">
                    {({ field }) => (
                      <FormControl isInvalid={false}>
                        <Checkbox {...field} mt={4} value="newsletter">
                          Stay in touch with occasional updates from Sundog
                          Homes.
                        </Checkbox>
                      </FormControl>
                    )}
                  </Field> */}
                  <Button
                    colorScheme={buttonColor.split(`.`)[0]}
                    id={buttonId}
                    isDisabled={!props.touched || !props.isValid || props.isSubmitting}
                    isLoading={props.isSubmitting}
                    mt={6}
                    size="lg"
                    type="submit"
                    w={{ base: `100%`, md: `200px` }}
                  >
                    {buttonText}
                  </Button>
                  <Text color="gray.500" fontSize="sm" mt={4}>
                    We'll never share this information with anyone.{` `}
                    <Link
                      as={GatsbyLink}
                      color="gray.700"
                      to="/privacy/"
                      variant=""
                    >
                      View our privacy policy
                    </Link>
                    .
                  </Text>
                </Box>
              </Flex>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

export default ContactForm
