import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Text,
  VStack,
} from "@chakra-ui/react"
import * as React from "react"
import gsap from "gsap"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import ScrollTrigger from "gsap/ScrollTrigger"

export interface AnchorLinkProps extends ButtonProps {
  container: React.MutableRefObject<HTMLElement>
  title: string
}

type StartEndLocation = string | number | gsap.plugins.StartEndFunc

export interface AnchorLinkConfig {
  activeLinkStart?: StartEndLocation
  hideWrapperTween?: gsap.TweenVars
  revealWrapperTween?: gsap.TweenVars
  scrollOffsetY?: number
  wrapperEnd?: StartEndLocation
  wrapperStart?: StartEndLocation
}

export interface AnchorLinksProps extends BoxProps {
  activeLinkColor?: string
  config?: AnchorLinkConfig
  container: React.MutableRefObject<HTMLElement>
  inactiveLinkColor?: string
  links: AnchorLinkProps[]
  title?: string
}

const DEFAULT_CONFIG: AnchorLinkConfig = {
  activeLinkStart: `top+72px top+=50%`,
  hideWrapperTween: { duration: 0.3, right: `-=140px` },
  revealWrapperTween: { duration: 0.3, right: `0px` },
  wrapperEnd: `bottom+=72px top+=50%`,
  wrapperStart: `top top`,
}

function AnchorLinks({
  activeLinkColor = `red.500`,
  config = DEFAULT_CONFIG,
  container,
  inactiveLinkColor = `gray.500`,
  links,
  title,
  ...boxProps
}: AnchorLinksProps): JSX.Element {
  gsap.registerPlugin(ScrollToPlugin, ScrollTrigger)

  const [activeElement, setActiveElement] = React.useState<HTMLElement>()
  const anchorContainerRef = React.useRef<HTMLDivElement>()

  React.useEffect(() => {
    const closestToTop = links.reduce((acc, cur) => {
      if (cur.container) {
        const thisFromTop = cur.container.current.getBoundingClientRect().top
        const closestSoFar = acc.container.current.getBoundingClientRect().top
        if (Math.abs(thisFromTop) < Math.abs(closestSoFar)) {
          return cur
        }
      }
      return acc
    })

    ScrollTrigger.create({
      end: config.wrapperEnd,
      onEnter: revealAnchorWrapper,
      onEnterBack: revealAnchorWrapper,
      onLeave: hideAnchorWrapper,
      start: config.wrapperStart,
      trigger: container.current,
    })

    links.forEach(({ container }) => {
      ScrollTrigger.create({
        start: `${config.activeLinkStart}`,
        onEnter: () => setActiveElement(container.current),
        onEnterBack: () => setActiveElement(container.current),
        trigger: container.current,
      })
    })

    setActiveElement(closestToTop.container.current)
  }, [links])

  const hideAnchorWrapper = () => {
    gsap.to(anchorContainerRef.current, config.hideWrapperTween)
  }

  const revealAnchorWrapper = () => {
    gsap.to(anchorContainerRef.current, config.revealWrapperTween)
  }

  const scrollToElement = (element: HTMLElement) => {
    gsap.to(window, {
      duration: 0.5,
      onStart: () => setActiveElement(element),
      scrollTo: {
        offsetY: config.scrollOffsetY ?? window.innerHeight * 0.25,
        y: element,
      },
    })
  }

  return (
    <Box
      border="1px"
      borderBottomLeftRadius="lg"
      borderColor="gray.200"
      borderTopLeftRadius="lg"
      pos="fixed"
      py={4}
      ref={anchorContainerRef}
      right="0"
      top="calc(4.5rem + 10vh)"
      w="135px"
      {...boxProps}
    >
      <VStack alignItems="start" spacing={0} p={4}>
        {title && (
          <Text
            color="gray.600"
            mb={3}
            fontWeight="bold"
            w="100%"
            textAlign="center"
          >
            {title}
          </Text>
        )}
        {links.map(
          ({ container = null, title }: AnchorLinkProps, index: number) => {
            const isActive = container.current === activeElement
            return (
              <Box
                borderLeft="2px"
                borderLeftColor={isActive ? activeLinkColor : `gray.200`}
                key={index}
                my={0}
                py={1}
                rounded="sm"
                transition="ease-in"
                transitionDuration="0.3s"
                w="100%"
              >
                <Button
                  color={isActive ? activeLinkColor : inactiveLinkColor}
                  outline="none"
                  onClick={() => scrollToElement(container.current)}
                  ml={4}
                  transition="ease-in"
                  transitionDuration="0.3s"
                  variant="link"
                  _focus={{ boxShadow: `none` }}
                >
                  {title}
                </Button>
              </Box>
            )
          }
        )}
      </VStack>
    </Box>
  )
}

export default AnchorLinks
